@use "../theme";

.product-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 .5rem .5rem;
  border: 1px solid theme.$primary-light;
  border-radius: .6vw;
  &:hover:not(.unavailable) {
    box-shadow: theme.$shadow-3;
    transform: scale(1.05);
  }
  &.unavailable a *:not(div):not(.unfiltered) {
    filter:opacity(60%);
  }
  a {
    text-decoration: none;
    width: 100%;
    flex-grow: 1;
  }

  .img-container {
    display: grid;
    position: relative;
    grid-template: 1fr 50px / 1fr;
    width: 200px;
    margin: 0 auto 1rem;
    .picture {
      height: 150px;
      width: auto;
      grid-row: 1 / 3;
      margin: 25px 0;
    }
  }
  .card-footer {
    background-color: rgba(0, 0, 0, 0.5);
    grid-row: 2 / span 1;
    position: absolute;
    height: 100%;
    max-width: 100%;
    color: #ffffff;
    font-size: 1rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    p {
      width: 200px;
      padding: 0px 16px;
      margin: 0;
    }
  }
  .footer-text {
    flex: 1 1 auto,
  }
  .footer-subtitle {
    font-size: 0.75rem;
  }
  .add-to-cart {
    align-self: flex-start;
  }
  .svg {
    font-size: 200px;
    color: theme.$primary-light;
    grid-row: 1 / 3;
  }
}