.content-root {
  max-width: 800px;
  .contents {
    margin-top: 16px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 5em;
    & p {
      margin: 0;
      text-align: justify;
    }
    @media(max-width: 600px) {
      flex-flow: column nowrap;
      gap: 1em;
    }
  }
  // img {
  //   padding: 5px;
  //   margin: 0 5px;
  // }
}
