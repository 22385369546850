@use "../theme";

.product {
  &.unavailable *:not(div):not(.unfiltered) {
    filter: opacity(60%);
  }
  .picture {
    height: 186px;
    width: auto;
    margin: 31px 0;
  }
  .svg {
    font-size: 248px;
    color: theme.$primary-light;
  }
  .closer {
    cursor: pointer;
  }
  .header {
    padding: .5em 0;
  }
}
