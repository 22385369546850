@use "../theme";

.homepage {
  max-width: 800px;
  margin: 0 auto;
  
  .cta {
    background-color: theme.$primary-main;
    color: theme.$primary-contrast-text;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: '1rem';
    max-width: 800px;
    margin: 16px auto 32px;
  }
  .articles-container {
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding: 0 8px;
    @media (max-width: 799px) {
      gap: 40px;
    }
  }
  .map {
    width: 100%;
    padding: 5px;
    margin: 16px 0;
    iframe {
      width: 100%;
    }
  }
}