$primary-main: #a28d88;
$primary-light:#b4a39f;
$primary-dark: #71625f;
$secondary-main: #c62b02;
$secondary-light: rgb(209, 85, 52);
$success: #2e7d32;
$error: #d32f2f;
$warning: #ed6c02;
$primary-contrast-text: #ffffff;
$text-secondary: rgba(0, 0, 0, 0.6);
$zindex-drawer: 1200;
$shadow-1: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
$shadow-3: 0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12);