.cart {
  padding-left: 1rem;
  max-width: 800px;
  margin: 0 auto;
  .cartlist {
    padding: 8px 16px;
  }
  .order-button {
    max-width: 200;
  }
}
