.quantity-control {
  display: flex;
  flex-direction: row;
  align-items: center;
  input {
    width: 3em;
    max-height: 2em;
    text-align: center;
  }
}
