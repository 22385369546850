$primary-color: #a28d88;
$body-fonts: Roboto, 'Open Sans', sans-serif;

.home {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  .banner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    width: 100%;
    margin: 0 auto;
    padding: 80px 0;
  }
  .banner-content {
    // background-color: rgba(0, 0, 0, 0.5);
    padding: 0 .5rem;
  }
  .content {
    margin: 0 auto;
    padding: 80px 5px;
    width: 100%;
  }
  .full-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  }
  .toolbar {
    display: flex;
    justify-content: space-between;
    margin: auto auto 0;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .site-header {
    color: #fff;
    text-decoration: none;
  }
  .tabs {
    color: #fff;
  }
  .down-button-container {
    margin-top: 2em;
    border-radius: 50%;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.5);
    &:hover {
      background-color: rgba(0, 0, 0, .6);
    }
  }
}