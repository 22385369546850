.review {
  max-width: 800px;
  max-width: 800px;
  padding: 0 1rem;
  margin-left: 1rem;
  .appbar {
    position: relative;
  }
  .info-and-cart {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .info {
    margin-right: 2rem;
  }
  section {
    margin-bottom: 2rem;
  }
  .breadcrumbs {
    margin-left: 1rem;
  }
  .link {
    cursor: pointer;
  }
}
