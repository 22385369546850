@use "../theme";

.selected {
  box-shadow: theme.$shadow-3;
  transform: scale(1.08);
}
.category-button {
  color: #000000;
  padding: 8px;
  border-radius: .6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    transform: scale(1.05);
    box-shadow: theme.$shadow-1;
  }
  .image-container {
    display: flex;
    object-position: center;
    border-radius: 50%;
    overflow: hidden;
    height: 96px;
    width: 96px;
  }
  .picture {
    width: 96px;
    height: 72px;
    margin: 12px 0;
  }
  .svg {
    font-size: 92px;
    color: theme.$primary-light;
  }
  @media (max-width: 480px) {
    .image-container {
      width: 48px;
      height: 48px;
    }
    .picture {
      width: 48px;
      height: 36px;
      margin: 6px 0;
    }
    .svg {
      font-size: 48px;
    }
  }
}