@use "../theme";

.orderform {
  .input {
    margin: 16px 1rem;
  }
  .form-and-cart-container {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 800px;
  }
  .form-container {
    flex-grow: 1;
  }
  .cart {
    max-width: 250px;;
    margin-left: 1rem;
    // border-left: 1px solid theme.$primary-main;
  }
  .button {
    margin-top: 1rem;
  }
  fieldset {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    // border: solid 1px theme.$primary-light;
    border: none;
    margin-top: 16px;
  }
}
