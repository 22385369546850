@use "../theme";

.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .menu-and-cart {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
  .menu-container {
    flex-grow: 1;
    max-width: 100%;
    margin-right: .5rem;
    display: flex;
    flex-direction: column;
  }
  .categories {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    gap: 8px;
    margin: 24px 4px;
  }
  hr {
    margin: 5px;
  }
  .category-title {
    width: 100%;
    background-color: theme.$primary-light;
    margin: 24px 0;
  }
  .gridlist {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
    grid-gap: 1rem;
    justify-content: space-around;
    flex-grow: 1;
  } 
  .cart-container {
    width: 250px;
    border-left: 1px solid theme.$primary-main;
  }
}