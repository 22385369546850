@use "../theme";

.order-confirmed {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 8px;
  .page-title {
    margin-bottom: 32px;
  }
  .details {
    z-index: theme.$zindex-drawer + 1;
  }
  section {
    margin: 24px 0;
  }
}
