@use "../theme";

.cartline {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 1em;
  .list-contents, .list-contents-small {
    display: flex;
  }
  .list-contents, .add-to-cart {
    flex: 1 1 0;
  }
  .list-contents-small, .add-to-cart-small {
    flex: 0 1 auto;
  }
  .list-text {
    margin-left: 1em;
  }
  .strike {
    text-decoration-line: line-through;
    text-decoration-color: theme.$text-secondary;
  }
  .avatar {
    background-color: #ffffff;
    .picture {
      width: 40px;
      height: 30px;
      margin: 5px 0;
    }
    .svg {
      font-size: 40px;
      color: theme.$primary-main;
    }
  }
}
